import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    activeItem!: number;
    logoutDialog:boolean=false;
    isUserAuthenticated: boolean=false;
    constructor(public layoutService: LayoutService, 
        public el: ElementRef,
        private authService:AuthenticationService,
        private router:Router) {
            this.authService.authChanged
  .subscribe(res => {
    this.isUserAuthenticated = res;
  })
        }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    public logout = () => {
        this.authService.logout();
        this.router.navigate(["/"]);
      }
      openLogoutDialog(){
        this.logoutDialog=true;
    }
    public confirmLogout = () => {
        this.authService.logout();
        this.logoutDialog=false;
        this.router.navigate(["/auth/login"]);

      }

}
