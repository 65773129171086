import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  {
    path: '',component: AppLayoutComponent,
    children: [
      { path: '', component:AppComponent  ,canActivate:[AuthGuard]},
      { path: 'emplmngmt', data: { breadcrumb: 'Employee Management' }, loadChildren: () => import('./pages/employee/employee.module').then((m) => m.EmployeeModule) },
    
     
    ]
  },
  { path: 'auth', data: { breadcrumb: 'Auth' }, loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule) },




];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
