<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger transition-duration-300" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
    </div>
    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
           
            <li class="topbar-item">
              
                <ul [class]="'topbar-menu active-topbar-menu p-4 w-15rem   z-5 ng-hidden'">
                    <li role="menuitem" class="m-0 mb-3">
                        <a href="#" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-lock mr-2"></i>
                            <span>Privacy</span>
                        </a>
                    </li>
                    <li role="menuitem" class="m-0 mb-3">
                        <a href="#" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-cog mr-2"></i>
                            <span>Settings</span>
                        </a>
                    </li>
                    <li role="menuitem" class="m-0">
                        <a href="#" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-sign-out mr-2"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
          
            
            <li class="">
                <button pButton type="button" icon="pi pi-power-off" class="p-button-text p-button-secondary flex-shrink-0" (click)="openLogoutDialog()"></button>
            </li>
        </ul>
    </div>
</div>
<p-dialog [(visible)]="logoutDialog" header="Confirm" [modal]="true" [style]="{ width: '450px' }">
    <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span>Are you sure you want to logout?</span>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No"
            (click)="logoutDialog = false"></button>
        <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes"
            (click)="confirmLogout()"></button>
    </ng-template>
</p-dialog>