<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo cursor-pointer">
            <img src="assets/images/logos/IO_svg.svg" class ="logo" alt="logo" />
        </a>
        <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>
    </div>

    <div #menuContainer  class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>
