import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { LogEntry } from 'src/app/models/logging/logEntry';
import { environment } from 'src/environments/environment';

export enum LogLevel {
    All = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
    Fatal = 5,
    Off = 6
}

@Injectable()

export class LogService {
   
level: LogLevel = LogLevel.All;
logWithDate: boolean = true;
log_url = "http://localhost:5195/api/LogEntries";
constructor(private http: HttpClient) {

}

    debug(msg: string,user:string, ...optionalParams: any[]) {
        this.writeToLog(msg,user, LogLevel.Debug, optionalParams);
    }
    
    info(msg: string,user:string, ...optionalParams: any[]) {
        this.writeToLog(msg, user,LogLevel.Info, optionalParams);
    }
    
    warn(msg: string,user:string, ...optionalParams: any[]) {
        this.writeToLog(msg,user, LogLevel.Warn, optionalParams);
    }
    
    error(msg: string,user:string, ...optionalParams: any[]) {
        this.writeToLog(msg,user, LogLevel.Error, optionalParams);
    }
    
    fatal(msg: string,user:string, ...optionalParams: any[]) {
        this.writeToLog(msg,user, LogLevel.Fatal, optionalParams);
    }
    
    log(msg: string,user:string, ...optionalParams: any[]) {
        this.writeToLog(msg, user,LogLevel.All, optionalParams);
    }
    private writeToLog(msg: string,user:string, level: LogLevel, params: any[]) {
        if (this.shouldLog(level,msg)) {
            
            let entry: LogEntry = new LogEntry();
            entry.message = msg;
            entry.level = level;
            entry.extraInfos = params;
            entry.user=user;
            entry.application=environment.applicationName;
            entry.company=environment.companyName;
            entry.logWithDate = this.logWithDate;
            console.log(entry);

            this.PostLog(entry).subscribe();
     
        }
    }
    
    PostLog(entry: LogEntry){
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options =  {
             headers: headers
             };
        
        return this.http.post(this.log_url, entry, options);
    }
      
    private shouldLog(level: LogLevel,msg:string): boolean {
        let ret: boolean = false;
        if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
            ret = true;
        }
        if(msg.includes("api/LogEntries")){
            ret=false;
        }
        return ret;
    }
    
}
