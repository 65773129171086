import { Component } from '@angular/core';
import { AuthenticationService } from './shared/services/authentication.service';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AuthenticationTemplate';
  constructor(private authService: AuthenticationService,private router :Router,private primengConfig:PrimeNGConfig){}
  
  ngOnInit(): void {
    this.primengConfig.ripple = true;
    if(this.authService.isUserAuthenticated()){
      this.authService.sendAuthStateChangeNotification(true);
    }
    else{
      // if(this.router.url.startsWith("/auth")){
      //   return;
      // }
      // else{
      //   this.router.navigate(["/auth/login"]);
      // }
      
    }
      
  }
}
