import { LogLevel } from "src/app/shared/logging/logService";
import { LogInfoParam } from "./logInfoParam";



export class LogEntry {
    // Public Properties
    entryDate: Date = new Date();
    message: string = "";
    level: LogLevel = LogLevel.Debug;
    extraInfos: LogInfoParam[] = [];
    logWithDate: boolean = true;
    user:string="";
    company:string="";
    application:string="";
   
    buildLogString(): string {
        let ret: string = "";
        
        if (this.logWithDate) {
            ret = new Date() + " - ";
        }
        
        ret += "Type: " + LogLevel[this.level];
        ret += " - Message: " + this.message;
        if (this.extraInfos.length) {
            ret += " - Extra Info: " + this.formatParams(this.extraInfos);
        }
        
        return ret;
    }
    
    private formatParams(params: any[]): string {
        let ret: string = params.join(",");
        
        // Is there at least one object in the array?
        if (params.some(p => typeof p == "object")) {
            ret = "";
            
            // Build comma-delimited string
            for (let item of params) {
                ret += JSON.stringify(item) + ",";
            }
        }
        
        return ret;
    }
}
