import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];

    constructor(
        private authService:AuthenticationService,
    ){

            let customerId=this.authService.getCustomerId();
            let custId= Number.parseInt(customerId);
            if(custId==7){
                this.model = [
                    {
                        label: 'Employee Management',
                        icon: 'pi pi-user',
                        items: [
                            {
                                label: 'Employees',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/emplmngmt/employees']
                            },
                            {
                                label: 'Sector Rights',
                                icon: 'pi pi-fw pi-lock',
                                routerLink: ['/emplmngmt/sector-rights']
                            },
                            {
                                label:'Drivers',
                                icon: 'pi pi-fw pi-car',
                                routerLink: ['/emplmngmt/drivers']
                            }
        
                           
                        ]
                    },
                    
                ];
            }
            else{
                this.model = [
                    {
                        label: 'Employee Management',
                        icon: 'pi pi-user',
                        items: [
                            {
                                label: 'Employees',
                                icon: 'pi pi-fw pi-id-card',
                                routerLink: ['/emplmngmt/employees']
                            },
                            {
                                label: 'Sector Rights',
                                icon: 'pi pi-fw pi-lock',
                                routerLink: ['/emplmngmt/sector-rights']
                            },
                           
                           
                        ]
                    },
                    
                ];
            }
           

    }
    ngOnInit() {
   
    }
}
