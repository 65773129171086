import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LogService } from '../logging/logService';
import { LogInfoParam } from 'src/app/models/logging/logInfoParam';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements HttpInterceptor {
  
  constructor(private router: Router, private logService:LogService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = this.handleError(error,req);
        return throwError(() => new Error(errorMessage));
      })
    )
  }
  private handleError = (error: HttpErrorResponse,req:HttpRequest<any>) : string => {
   
    console.log(error)
    let extraParams:LogInfoParam[]= [
      {
        key:`Error for url ${req.urlWithParams}`,
        value:error.message
      },
      {
        key:`HTTP Error:${error.status}`,
        value:error.message
      }
    ]
    
    this.logService.log(error.message,"User",...extraParams);
    if(error.status === 404){
      return this.handleNotFound(error);
    }
    else if(error.status === 400){
      return this.handleBadRequest(error);
    }
    else if(error.status === 401) {
      return this.handleUnauthorized(error);
    }
    else if(error.status === 403) {
      return this.handleForbidden(error);
    }
    else{
      return error.message;
    }
  }
 
  private handleForbidden = (error: HttpErrorResponse) => {
    this.router.navigate(["/auth/access"], { queryParams: { returnUrl: this.router.url }});
    return "Forbidden";
  }
  private handleUnauthorized = (error: HttpErrorResponse) => {
    if(this.router.url .startsWith('/auth/login')) {
      return error.error.errorMessage;
    }
    else {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url }});
      return error.message;
    }
  }
  private handleNotFound = (error: HttpErrorResponse): string => {
    this.router.navigate(['/404']);
    return error.message;
  }
  
  private handleBadRequest = (error: HttpErrorResponse): string => {
    if(this.router.url === '/auth/register'|| 
    this.router.url.startsWith('/auth/newpassword')){
      let message = '';
      const values = Object.values(error.error.errors);
      values.map((m) => {
         message += m + '<br>';
      })
  
      return message.slice(0, -4);
    }
    else{
      return error.error ? error.error : error.message;
    }
  }
}